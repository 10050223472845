<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="ressources"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      @click:row="handleTableClick"
    >
      <template v-slot:no-data>
        Aucune ressources
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">EQUIPE</v-toolbar-title>
          <text-input
            prepend-inner-icon="mdi-magnify"
            @keyup="handleSearch"
            class="ml-5 mt-5"
            id="search"
            v-model="search"
            placeholder="Nom de l'agent"
          />
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                action_name="Ajouter"
                class="mb-2"
                color="primary"
                text
                @clicked="openAddDialog"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0 text-center" cols="12" md="12" sm="6">
                        <input
                          ref="file"
                          accept="image/*"
                          name="image"
                          class="d-none"
                          type="file"
                          @change="setImage"
                        />
                        <v-avatar v-if="showUserImage" size="250">
                          <img
                            :src="userImage"
                            alt="user-image"
                            class="pointer"
                            @click="select_image"
                          />
                        </v-avatar>
                        <div v-if="imageSrc">
                          <vue-cropper
                            ref="cropper"
                            :aspectRatio="ratio"
                            :guides="true"
                            :src="imageSrc"
                            class="mr-2 w-50"
                          ></vue-cropper>
                        </div>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          id="name"
                          v-model="editedItem.name"
                          :rules="requiredRules"
                          label="Nom et prénom*"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          id="email"
                          v-model="editedItem.email"
                          :rules="requiredRules"
                          label="Email*"
                          type="email"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="department"
                          v-model="editedItem.department"
                          :items="departments"
                          :rules="requiredRules"
                          label="Département*"
                          return-object
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="function"
                          v-model="editedItem.function"
                          :items="roles"
                          :rules="requiredRules"
                          label="Fonction*"
                          return-object
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="temps"
                          v-model="editedItem.role"
                          :items="types"
                          :rules="requiredRules"
                          label="Type de compte*"
                          return-object
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="status"
                          v-model="editedItem.status"
                          :items="account_status"
                          item-text="name"
                          item-value="id"
                          label="Statut*"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col
                        v-if="editedIndex === -1"
                        class="pa-0"
                        cols="12"
                        md="12"
                        sm="6"
                      >
                        <AutoComplete
                          id="dossier"
                          v-model="editedItem.dossierIds"
                          :items="activeDossiers"
                          item-text="name"
                          item-value="id"
                          label="Dossier"
                          multiple
                          deletable-chips
                          small-chips
                        />
                      </v-col>
                      <v-col
                        :class="{ 'disabled-field': editedIndex !== -1 }" 
                        class="pa-0"
                        cols="12"
                        md="12"
                        sm="6"
                      >
                        <label>Date d'embauche*</label>
                        <custom-date-picker v-model="editedItem.hiringDate" />
                      </v-col>
                      <v-col
                        v-if="editedIndex !== -1"
                        class="pa-0"
                        cols="12"
                        md="12"
                        sm="6"
                      >
                        <label>Date de fin de contrat</label>
                        <custom-date-picker v-model="editedItem.endContractDate" />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  v-if="!showEditButton"
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  :loading="loadingContinue"
                />
                <Button
                  v-if="!showEditButton"
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  @clicked="save(false)"
                  :loading="loadingAdd"
                />
                <Button
                  v-if="showEditButton"
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  :loading="loadingEdit"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <Avatar :item="item" class="mr-2" />
        <span>{{ item.name }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import {
  Button,
  Avatar,
  TextInput,
  AutoComplete,
  CustomDatePicker,
} from "@/components/base";
import {
  createUser,
  getAllUsers,
  searchUser,
  updateUserInfo,
} from "@/services/users.api";
import departments from "@/assets/data/departments.json";
import functions from "@/assets/data/functions.json";
import { getAllRoles } from "../../../services/roles.api";
import { getActiveDossiers } from "../../../services/dossiers.api";

export default {
  components: {
    Button,
    Avatar,
    TextInput,
    AutoComplete,
    VueCropper,
    CustomDatePicker,
  },
  data: () => ({
    loadingAdd: false,
    loadingContinue: false,
    loadingEdit: false,
    search: "",
    showUserImage: true,
    imageSrc: "",
    ratio: 1,
    imageHost: "",
    functions: functions,
    account_status: [
      {
        id: 1,
        name: "actif",
      },
      {
        id: 0,
        name: "Inactif",
      },
    ],
    types: ["agent", "admin"],
    showEditButton: false,
    expired: false,
    items: [],
    departments: departments,
    roles: [],
    dialog: false,
    user: {
      image: "default.jpg",
    },
    headers: [
      {
        text: "Agent",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Fonction", value: "function" },
      { text: "Compte", value: "role" },
      { text: "Département", value: "department" },
      // { text: "Actions", value: "actions", sortable: false }
    ],
    ressources: [],
    editedIndex: -1,
    editedItem: {
      image: "default.jpg",
    },
    defaultItem: {},
    imageName: "",
    activeDossiers: [],
    isValid: true,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Créer un compte" : "Modifier compte";
    },
    userImage() {
      return (
        this.imageHost +
        (this.editedItem.image ? this.editedItem.image : "default.jpg")
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.imageHost = process.env.VUE_APP_PHOTOS_PATH;
    this.initialize();
  },

  methods: {
    handleSearch() {
      if (this.search !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          searchUser(this.search).then((response) => {
            this.ressources = response.data;
          });
        }, 1000);
      } else {
        getAllUsers().then((response) => {
          this.ressources = response.data;
        });
      }
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.dialog = true;
    },
    setImage: function(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSrc = event.target.result;
          // Rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.imageName = file.name;
        this.showUserImage = false;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    handleTableClick(row) {
      this.editItem(row);
      this.showEditButton = true;
    },
    initialize() {
      this.getAllRoles();
      getAllUsers().then((response) => {
        this.ressources = response.data;
      });
      getActiveDossiers().then((response) => {
        this.activeDossiers = response.data.dossiers;
      });
    },
    getAllRoles() {
      getAllRoles().then((response) => {
        this.roles = response.data.map((role) => role.name);
      });
    },
    editItem(item) {
      this.editedIndex = this.ressources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showEditButton = false;
      });
    },
    select_image() {
      this.$refs.file.click();
    },
    updateInfo(item) {
      this.loadingEdit = true;
      updateUserInfo(item).then((response) => {
        this.loadingEdit = false;
        Object.assign(this.ressources[this.editedIndex], response.data);
        this.showUserImage = true;
        this.imageSrc = false;
        this.imageName = "";
        this.editedItem.image = "default.jpg";
        this.close();
        this.$swal.fire({ title: "Compte modifié avec succés" });
      });
    },
    update() {
      if (this.imageName) {
        const _self = this;
        this.croppedImageSrc = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.$refs.cropper.getCroppedCanvas().toBlob(function(blob) {
          let formData = new FormData();
          formData.append("image_name", _self.imageName);
          formData.append("file", blob);
          formData.append("id", _self.editedItem.id);
          formData.append("name", _self.editedItem.name);
          formData.append("email", _self.editedItem.email);
          formData.append("department", _self.editedItem.department);
          formData.append("function", _self.editedItem.function);
          formData.append("status", _self.editedItem.status);
          formData.append("role", _self.editedItem.role);
          formData.append("endContractDate", _self.editedItem.endContractDate);
          _self.updateInfo(formData);
        });
      } else {
        this.updateInfo(this.editedItem);
      }
    },
    AddNewUser(item, dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      if (!this.editedItem.hiringDate) {
        this.$swal.fire({
          icon: "error",
          title: "Le champ Date d'embauche est obligatoire",
        });
        return;
      }
      dialogStat ? (this.loadingContinue = true) : (this.loadingAdd = true);
      createUser(item)
        .then((response) => {
          dialogStat ? (this.loadingContinue = true) : (this.loadingAdd = true);
          this.ressources.push(response.data);
          this.close();
          this.$refs.form.reset();
          this.dialog = dialogStat;
          this.showUserImage = true;
          this.imageSrc = false;
          this.imageName = "";
          this.$swal.fire({ title: "Compte ajouté avec succés" });
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Cette adresse email est déjà utilisée",
          });
        });
    },
    save(dialogStat) {
      if (this.imageName) {
        const _self = this;
        this.croppedImageSrc = this.$refs.cropper
          .getCroppedCanvas()
          .toDataURL();
        this.$refs.cropper.getCroppedCanvas().toBlob(function(blob) {
          let formData = new FormData();
          formData.append("image_name", _self.imageName);
          formData.append("file", blob);
          formData.append("name", _self.editedItem.name);
          formData.append("email", _self.editedItem.email);
          formData.append("department", _self.editedItem.department);
          formData.append("function", _self.editedItem.function);
          formData.append("status", _self.editedItem.status);
          formData.append("role", _self.editedItem.role);
          formData.append("dossierIds", _self.editedItem.dossierIds);
          formData.append("hiringDate", _self.editedItem.hiringDate);
          _self.AddNewUser(formData, dialogStat);
        });
      } else {
        this.AddNewUser(this.editedItem, dialogStat);
      }
    },
  },
};
</script>
<style scoped>
.disabled-field {
  pointer-events: none;
}
</style>